<template>
  <transition name="toBottom">
    <header v-show="setShowAuthForm || showMenuBar" class="menu-main">
      <!-- <i
        v-if="isDesktop && showSnow"
        @click="showSnow = !showSnow"
        class="snow-switch" title="Выключить снег"
      />
      <snowComponent v-if="isDesktop && showSnow" /> -->

      <nav v-if="isDesktop" class="menu-desktop">
        <div class="header-menu">
          <div class="logotype">
            <nuxt-link :to="'/'">
              <span itemprop="name">
                Moredoram
              </span>
              <!-- <span class="tagline">
                Какой-то слоган
              </span> -->
            </nuxt-link>
            <!-- <i /> -->
          </div>

          <div class="menu-search">
            <ul
              v-if="!showSearchInput"
              :class="{'main-menu': true, mbottom: true, active: showMenu}"
            >
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="updates" to="/updates">
                  <strong>Обновления <sup>&#128293;</sup></strong>
                </nuxt-link>
              </li>
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="random" to="/random">
                  <strong>Случайная дорама</strong>
                </nuxt-link>
              </li>
              <li :class="{ active: showMenu }" @mouseenter="showMenus('Menu')" @mouseleave="hideMenus('Menu')">
                <nuxt-link data-route="watch" to="/watch">
                  <strong>Каталог дорам</strong>
                </nuxt-link>
              </li>
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="schedule" to="/schedule">
                  <strong>Расписание</strong>
                </nuxt-link>
              </li>
              <li @mouseenter="showMenu = false">
                <nuxt-link data-route="cinema" to="/vmeste/">
                  <strong>Совместный просмотр <sup>&#128293;</sup></strong>
                </nuxt-link>
              </li>
            </ul>
            <div v-else class="ssearch">
              <i :class="{ focused }" class="icon-search" />
              <searchhints-component :type="searchType" />
            </div>
            <i
              v-if="!showSearchInput"
              @click="showSearchInput = !showSearchInput"
              class="ms-icons search-open"
            />
            <i
              v-else
              @click="showSearchInput = !showSearchInput"
              class="ms-icons search-close"
            />
          </div>

          <div class="profile">
            <div style="display: grid;grid-template-columns: repeat(2, auto);align-items: center;">
              <!-- <i @click="switchTheme" title="Сменить тему" class="icon-moon" /> -->
              <!-- <i @click="switchLocale" title="Отображать англ названия" class="icon-locale" /> -->
              <template v-if="user">
                <transition name="toBottom">
                  <ul v-if="showProfileMenu">
                    <nuxt-link to="/donate" class="store">&#128176; Баланс: {{ user.balance }} монет</nuxt-link>
                    <span :class="{ 'active': showProfileMenu}" @click="showProfileMenu = !showProfileMenu">
                      <!-- {{ user.email }} -->
                      <i class="icon-close" />
                    </span>
                    <li class="user-name">
                      <strong>ID: {{ user.id }}</strong>
                    </li>
                    <li class="user-name">
                      <strong>{{ user.name ? user.name : 'No name' }}</strong>
                    </li>
                    <li class="profile-avatar">
                      <div style="position: relative;">
                        <div :class="`group-${user.group}`" class="avatar">
                          <img
                            :src="user.photo || '/no-avatar.svg'"
                            alt=""
                          >
                        </div>
                      </div>

                      <!-- <span @click="avatarUploadTrigger">Изменить</span>
                      <input
                        ref="avatarUpload"
                        type="file"
                        style="display:none;"
                        accept="image/gif, image/png, image/jpeg, image/jpg"
                        @change="avatarUpload"
                      > -->
                    </li>

                    <li style="font-size:1.2rem;text-transform:uppercase;">
                      <nuxt-link to="/donate" tag="span">
                        <b
                          style="margin-right:5px;padding:0px 6px;color:#000;border-radius:20px;background-color:#fad200;"
                        >Premium</b>
                        <div v-if="user.group === 777">
                          <template v-if="user.premium_date">до {{ user.premium_date | filterDate }}</template>
                          <template v-else>Бессрочный</template>
                        </div>
                        <div v-else>
                          Подключить
                        </div>
                      </nuxt-link>
                    </li>

                    <nuxt-link v-if="!showSettging" :to="'/profile'" tag="li" class="open-profile">
                      Открыть профиль
                    </nuxt-link>
                    <li>
                      <nuxt-link to="/notifications">&#128276; Уведомления <sup>{{ enterData.notifications > 99 ? `99+` : enterData.notifications }}</sup></nuxt-link>
                    </li>
                    <li>
                      <nuxt-link to="/profile?tab=favs">Закладки</nuxt-link>
                    </li>
                    <!-- <li>
                      <nuxt-link to="/subscribe">Подписки</nuxt-link>
                    </li> -->

                    <li v-if="!showSettging" @click="logout" style="cursor:pointer;">
                      Выйти
                    </li>
                  </ul>
                </transition>
                <div
                  @click="showProfileMenu = !showProfileMenu"
                  :class="`group-${user.group}`"
                  class="short-profile-avatar"
                >
                  <img
                    :src="user.photo || '/no-avatar.svg'"
                    alt=""
                  >
                  <sup v-if="enterData.notifications">{{ enterData.notifications > 99 ? `99+` : enterData.notifications }}</sup>
                </div>
              </template>
              <template v-else>
                <transition name="toBottom">
                  <div v-if="showAuthForm" class="auth-form">
                    <button :class="{ active: showAuthForm }" @click="showAuthForm = !showAuthForm">
                      Вход на сайт
                      <i class="icon-close" />
                    </button>
                    <div class="auth-social">
                      <span @click="authSocial('google')" class="google"><i /> Google</span>
                      <!-- <span @click="authSocial('vkontakte')" class="vkontakte"><i /> ВКонтакте</span> -->
                      <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
                      <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
                    </div>
                  </div>
                </transition>
                <button :class="{ active: showAuthForm }" @click="showAuthForm = !showAuthForm">
                  Войти
                </button>
              </template>
            </div>
          </div>
        </div>

        <div v-if="showMenu" class="sub-menus" @mouseleave="showMenu = false">
          <div class="wrapper">
            <div
              v-if="showMenu"
              onselectstart="return false"
              onmousedown="return false"
              class="sub-menu"
              @mouseenter="showMenu = true"
              @mouseleave="showMenu = false"
            >
              <h2>
                Самые популярные
              </h2>
              <div v-if="enterData.top_anime.length" class="top-videos">
                <nuxt-link
                  v-for="film in enterData.top_anime"
                  :key="`monthtop-menu-${film.kinopoisk_id}`"
                  :title="film.title_ru"
                  :to="'/watch/' + postUrl(film.kinopoisk_id || film.mdl_id || film.wa_id, film.title_ru)"
                  class="top-item"
                >
                  <img v-if="showMenu" :src="filmPoster(film)" :alt="'Постер ' + film.title_ru">
                  <strong>{{ film.title_ru.length > 35 ? `${film.title_ru.slice(0, 35)}...` : film.title_ru }}</strong>
                </nuxt-link>
              </div>
              <h3>
                Дорамы по жанрам
              </h3>
              <span v-for="g in genres" :key="`main-menu-genre${g}`">
                <nuxt-link :to="'/watch?genre=' + g">
                  {{ g }}
                </nuxt-link>
              </span>
              <h3>
                Дорамы по странам
              </h3>
              <span v-for="c in countries" :key="`main-menu-country${c}`">
                <nuxt-link :to="'/watch?country=' + c">
                  {{ c }}
                </nuxt-link>
              </span>
              <h3>
                Дорамы по годам
              </h3>
              <span v-for="y in years.slice(0, 10)" :key="`main-menu-years-${y}`">
                <nuxt-link :to="'/watch?year=' + y">
                  {{ y }}
                </nuxt-link>
              </span>
            </div>
          </div>
        </div>

        <div class="other-cats">
          <strong>Тоже интересно:</strong>
          <nuxt-link data-route="popular" to="/popular">&#127942; Топ 100 дорам</nuxt-link>
          <nuxt-link data-route="users" to="/users">Поиск друзей</nuxt-link>
          <nuxt-link data-route="new" to="/new">Новинки дорам {{ new Date().getFullYear() }} <sup>&#128293;</sup></nuxt-link>
          <!-- <nuxt-link data-route="blogs" to="/blogs">Блоги</nuxt-link> -->
          <nuxt-link data-route="clips" to="/clips">Клипы <sup>&#128293;</sup></nuxt-link>
        </div>
      </nav>
      <nav v-else class="menu-mobile">
        <div class="header-menu">
          <div class="left">
            <i @click="toggleMenu = !toggleMenu" :class="{ 'menu-mobile-toggle': true, 'close': toggleMenu }" />
            <!-- <i @click="switchTheme" class="icon-moon" /> -->
          </div>
          <div class="logotype">
            <nuxt-link :to="'/'">
              <span>
                Moredoram
              </span>
            </nuxt-link>
            <i />
          </div>
          <div class="right">
            <i class="icon-short-search" @click="showSearch" />
            <i v-if="!user" class="icon-short-profile" @click="showAuthForm = !showAuthForm" />
            <div
              v-else
              @click="showProfileMenu = !showProfileMenu"
              :class="`group-${user.group}`"
              class="short-profile-avatar mobile"
            >
              <img
                :src="user.photo || '/no-avatar.svg'"
                alt=""
              >
              <sup v-if="enterData.notifications">{{ enterData.notifications > 99 ? `99+` : enterData.notifications }}</sup>
            </div>
            <div class="profile">
              <template v-if="user">
                <transition name="toBottom">
                  <ul v-if="showProfileMenu">
                    <nuxt-link to="/donate" class="store">&#128176; Баланс: {{ user.balance }} монет</nuxt-link>
                    <span :class="{ 'active': showProfileMenu}" @click="showProfileMenu = !showProfileMenu">
                      <!-- {{ user.email }} -->
                      <i class="icon-close" />
                    </span>
                    <li class="user-name">
                      <strong>ID: {{ user.id }}</strong>
                    </li>
                    <li class="user-name">
                      <strong>{{ user.name ? user.name : 'No name' }}</strong>
                    </li>
                    <li class="profile-avatar">
                     <div style="position: relative;">
                        <div :class="`group-${user.group}`" class="avatar">
                          <img
                            :src="user.photo || '/no-avatar.svg'"
                            alt=""
                          >
                        </div>
                      </div>
                      <!-- <span @click="avatarUploadTrigger">Изменить</span>
                      <input
                        ref="avatarUpload"
                        type="file"
                        style="display:none;"
                        accept="image/gif, image/png, image/jpeg, image/jpg"
                        @change="avatarUpload"
                      > -->
                    </li>

                    <li style="font-size:1.2rem;text-transform:uppercase;">
                      <nuxt-link to="/donate" tag="span">
                        <b
                          style="margin-right:5px;padding:0px 6px;color:#000;border-radius:20px;background-color:#fad200;"
                        >Premium</b>
                        <div v-if="user.group === 777">
                          <template v-if="user.premium_date">до {{ user.premium_date | filterDate }}</template>
                          <template v-else>Бессрочный</template>
                        </div>
                        <div v-else>
                          Подключить
                        </div>
                      </nuxt-link>
                    </li>
                    
                    <nuxt-link v-if="!showSettging" :to="'/profile'" tag="li" class="open-profile">
                      Перейти в профиль
                    </nuxt-link>
                    <li>
                      <nuxt-link to="/notifications">&#128276; Уведомления <sup>{{ enterData.notifications > 99 ? `99+` : enterData.notifications }}</sup></nuxt-link>
                    </li>
                    <li>
                      <nuxt-link to="/profile?tab=favs">Закладки</nuxt-link>
                    </li>
                    <!-- <li>
                      <nuxt-link to="/subscribe">Подписки</nuxt-link>
                    </li> -->
                    <li v-if="!showSettging" @click="logout" style="cursor:pointer;">
                      Выйти
                    </li>
                  </ul>
                </transition>
              </template>
              <template v-else>
                <transition name="toBottom">
                  <div v-if="showAuthForm" class="auth-form">
                    <button :class="{ active: showAuthForm }" @click="showAuthForm = !showAuthForm">
                      Вход на сайт
                      <i class="icon-close" />
                    </button>
                    <div class="auth-social">
                      <span @click="authSocial('google')" class="google"><i /> Google</span>
                      <!-- <span @click="authSocial('vkontakte')" class="vkontakte"><i /> ВКонтакте</span> -->
                      <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
                      <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
                    </div>
                  </div>
                </transition>
              </template>
            </div>
          </div>
        </div>
        <transition name="toBottom">
          <div v-if="openSearch" class="mobile-ssearch">
            <searchhints-component :type="searchType" />
          </div>
        </transition>
        <transition name="fade">
          <div v-if="toggleMenu" @click="toggleMenu = !toggleMenu" class="menu-mobile-bg" />
        </transition>
        <transition name="toRight">
          <div v-if="toggleMenu" class="menu-mobile-open">
            <h4>
              &#127942; <nuxt-link to="/popular">Топ 100 лучших дорам</nuxt-link>
            </h4>
            <div class="slider">
              <ul :style="'width:' + (enterData.top_anime.slice(0, 10).length * 190) + 'px'">
                <li v-for="(film, i) in enterData.top_anime.slice(0, 10)" :key="`mobile-menu-top-${i}`">
                  <img :src="filmPoster(film)" :alt="'Постер к ' + film.title_ru">
                  <strong v-if="film.title_ru.length > 30">{{ film.title_ru.slice(0, 30) }}...</strong>
                  <strong v-else>{{ film.title_ru }}</strong>
                  <nuxt-link :to="'/watch/' + postUrl(film.kinopoisk_id || film.mdl_id || film.wa_id, film.title_ru)" />
                </li>
              </ul>
            </div>

            <ul onselectstart="return false" onmousedown="return false" class="main-menu">
              <li>
                <nuxt-link to="/users">
                  Поиск друзей
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/vmeste/">
                  Совместный просмотр <sup>&#128293;</sup>
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/new">
                  Новинки {{ new Date().getFullYear() }} - {{ new Date().getFullYear() - 1 }}
                  <sup>&#128293;</sup>
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/watch">
                  Каталог дорам
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/updates">
                  Обновления каталогов
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/schedule">
                  Расписание
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/random">
                  Случайная дорама
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/clips">
                  Клипы
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/download">
                  Скачать дорамы
                </nuxt-link>
              </li>
              <!-- <li>
                <nuxt-link to="/blogs">
                  Блоги
                </nuxt-link>
              </li> -->
              <!-- <li>
                <i @click="switchLocale" class="icon-locale" />
              </li> -->
            </ul>

            <ul class="social">
              <li>
                <a href="https://twitter.com/moredoram" target="_blank" class="twitter" />
              </li>
              <li>
                <a href="https://vk.com/moredoram" target="_blank" class="vk" />
              </li>
              <li>
                <a href="https://www.facebook.com/groups/moredoram" target="_blank" class="facebook" />
              </li>
            </ul>
          </div>
        </transition>
      </nav>

      <transition name="fade">
        <div v-if="showFirstReg" @click="hideFirstReg" class="first-reg">
          <div onclick="event.stopPropagation()" class="modal">
            <div class="header">
              <span>Присоединяйся к нам!</span>
              <i class="icon-close" @click="hideFirstReg" />
            </div>
            <div class="body">
              <p>
                Зарегистрируйте аккаунт и получите преимущество!
              </p>
              <ul>
                <li>добавляйте дорамы в закладки</li>
                <li>создавайте комнаты для совместного просмотра</li>
                <li>оставляйте отзывы</li>
                <li>пишите рецензии</li>
                <li>общайтесь с другими пользователями</li>
                <li>находите новых друзей</li>
                <li>получайте уведомления о выходе новинок</li>
                <li>и многое другое...</li>
              </ul>
              <div class="auth-social">
                <span @click="authSocial('google')" class="google"><i /> Google</span>
                <!-- <span @click="authSocial('vkontakte')" class="vkontakte"><i /> ВКонтакте</span> -->
                <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
                <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </header>
  </transition>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    searchhintsComponent: () => import('~/components/searchhintsComponent.vue')
    // snowComponent:() => import('~/components/snowComponent.vue')
  },
  filters: {
    filterDate(value) {
      const day = new Date(value).getDate()  >= 10 ? new Date(value).getDate() : '0' + new Date(value).getDate()
      const month = new Date(value).getMonth() + 1  >= 10 ? new Date(value).getMonth() + 1 : '0' + (new Date(value).getMonth() + 1)
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day}.${month}.${year} ${hour}:${min}`
    }
  },
  watch: {
    openSearch () {
      this.toggleMenu = false
    },
    setShowAuthForm () {
      if (this.setShowAuthForm) this.showAuthForm = this.setShowAuthForm
    },
    changeRoute() {
      if (this.firstPet) this.hideFirstpet()
      this.activeLink()

      this.showMenu = false
      this.toggleMenu = false
      this.searchQuery = ''
      this.openSearch = false
      this.showProfileMenu = false
      this.showAuthForm = false
      function rand(min = 0, max = 255) {
        return `${
            Math.floor(Math.random() * (max - min + 1)) + min
          }, ${
            Math.floor(Math.random() * (max - min + 1)) + min
          }, ${
            Math.floor(Math.random() * (max - min + 1)) + min
          }`
      }
      const logo = document.querySelector('.header-menu .logotype span:first-child')
      logo.style.color = `rgba(${rand(100, 255)}, 1)`
    }
  },
  computed: {
    ...mapState(['error', 'user', 'setShowAuthForm', 'isDesktop', 'enterData']),
    changeRoute() {
      return this.$route.fullPath
    }
  },
  data() {
    const years = []
    const countries = [
      'Вьетнам',
      'Гонконг',
      'Индонезия',
      'Китай',
      'Малайзия',
      'Корея Северная',
      'Сингапур',
      'Таиланд',
      'Тайвань',
      'Филиппины',
      'Корея Южная',
      'Япония'
    ]
    const genres = [
      'биография',
      'боевик',
      'вестерн',
      'военный',
      'детектив',
      'детский',
      'для взрослых',
      'документальный',
      'драма',
      'игра',
      'история',
      'комедия',
      'концерт',
      'короткометражка',
      'криминал',
      'мелодрама',
      'музыка',
      'мультфильм',
      'мюзикл',
      'приключения',
      'реальное ТВ',
      'семейный',
      'спорт',
      'триллер',
      'ужасы',
      'фантастика',
      'фильм-нуар',
      'фэнтези'
    ]
    for(let i = new Date().getFullYear(); i >= 1958; i--) {
      years.push(i)
    }
    return {
      // showSnow: process.env.NODE_ENV === 'production',
      showCatsSpoiler: false,
      searchType: 'db',
      focused: false,
      years: Object.freeze(years),
      countries: Object.freeze(countries),
      genres: Object.freeze(genres),
      menuTimers: {},
      loading: false,
      toggleMenu: false,
      showProfileMenu: false,
      showAuthForm: false,
      showRegForm: false,
      showResetForm: false,
      showSettging: false,
      showFirstReg: false,
      name: '',
      email: '',
      password: '',
      photo: '',
      showEditAvatar: false,
      showEditName: false,
      showMenu: false,
      openSearch: false,
      showMenuBar: true,
      notifications: 0,
      showGetFirstpet: false,
      gets: {},
      firstPet: null,
      showVseMaykiBanner: true,
      showSearchInput: false
    }
  },
  mounted() {
    let lastPosition = 0

    window.addEventListener('scroll', () => {
      const iframeEnter = document.querySelector('.event-iframe iframe.enter')
      if (iframeEnter) {
        this.showMenuBar = false
        return
      }
      const currentPosition = document.documentElement.scrollTop
      if (currentPosition === 0) {
        if (!this.toggleMenu) this.showMenuBar = true
        lastPosition = 0
      } else if (lastPosition > currentPosition) {
        this.showMenuBar = true
      } else if (!this.toggleMenu) {
        this.showMenuBar = false
      }
      if (lastPosition < currentPosition - 50) lastPosition = currentPosition
    })
    this.activeLink()
  },
  methods: {
    closeBannerVseMayki() {
      this.showVseMaykiBanner = false
      const date = `${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`
      localStorage.setItem(`banner_vsemayki_${date}`, 'true')
    },
    hideFirstpet() {
      this.showGetFirstpet = false

      const obj = {
        date: Date.now(),
        type: 'first_pet',
        to: this.user.id,
        from: {
          id: null,
          name: 'Я кое-что нашел',
          photo: `/items/pet-${this.firstPet}.svg`,
          group: 1
        },
        message: `Твой питомец нашел 200 монет, которые ты можешь потратить в Магазине!`,
        payload: null
      }
      this.$socket.emit('md:notification', obj)

      this.firstPet = null

      // localStorage.setItem('first_pet', 'true')
    },
    async getFirstPet() {
      if (this.gets.getFirstPet) return
      this.gets.getFirstPet = true
      const { data } = await this.$axios.get('/api/items/first')
      if (data.error) {
        this.showGetFirstpet = false
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 3000)
      } else {
        this.firstPet = data
        const user = this.user
        user.pet = data
        this.$store.commit('setUser', user)
      }
      delete this.gets.getFirstPet
    },
    activeLink() {
      const linkList = document.querySelectorAll('[data-route]')
      if (linkList) {
        linkList.forEach(el => {
          const elem = el
          const routeName = this.$route.name
          if (
            routeName && this.$route.name.includes(el.dataset.route)
          ) {
            elem.style.color = "cyan"
          } else {
            elem.style.color = ''
          }
        })
      }
    },
    switchLocale() {
      if (!localStorage.getItem('localized')) {
        localStorage.setItem('localized', 'true')
        document.body.setAttribute('data-localized', 'en')
      } else {
        localStorage.removeItem('localized')
        document.body.removeAttribute('data-localized')
      }
    },
    switchTheme() {
      if (localStorage.getItem('theme')) {
        if (localStorage.getItem('theme') === 'dark') {
          document.body.className = ''
          localStorage.setItem('theme', 'light')
        } else {
          document.body.className = 'dark'
          localStorage.setItem('theme', 'dark')
        }
      } else {
        document.body.className = 'dark'
        localStorage.setItem('theme', 'dark')
      }
      // if (!(this.user && this.user.profile_bg)) {
      //   document.body.style.background = `url("/bg/bg_winter_${document.body.className}.webp") center top / cover no-repeat fixed`
      // }
    },
    hideFirstReg() {
      document.querySelector('.first-reg').style.display = 'none'
      localStorage.setItem('first_reg', 'true')
    },
    showMenus(type) {
      this.menuTimers[type] = setTimeout(() => {
        this['show' + type] = true
      }, 200)
    },
    hideMenus(type) {
      clearTimeout(this.menuTimers[type])
    },
    hideMenu(type) {
      this['show' + type] = false
    },
    showSearch() {
      this.openSearch = !this.openSearch
      this.searchHints = []
      this.searchQuery = ''
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      // console.log('logout', data)
      // this.$store.commit('setUser', null)
      // this.name = ''
      // this.email = ''
      // this.password = ''
      // this.showProfileMenu = false
      // this.showAuthForm = false
      // this.showRegForm = false
      location.reload()
    },
    authSocial(social) {
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=900,height=600,left=10,top=10`
      window.open(`/api/auth/${social}`, `Auth ${social}`, params)
    }
  }
}
</script>
